import { Inject, Injectable, OnDestroy } from '@angular/core';
import { VaTableSortService } from '../va-table-sort/va-table-sort.service';
import { TABLE_DEFINITION, TableDefinition } from '../va-table-definition/va-table-definition';
import { SubscriptionList } from '@vendasta/rx-utils';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { VaFilteredMatTableDataSource } from './va-filtered-mat-table-data-source';
import { FilterGroup } from '@vendasta/va-filter2';

@Injectable()
export class VaFilteredMatTableService<T> implements OnDestroy {
  private readonly subscriptions = SubscriptionList.new();

  private readonly paging$$ = new BehaviorSubject<PageEvent>({ length: 0, pageSize: 25, pageIndex: 0 });
  paging$: Observable<PageEvent> = this.paging$$.asObservable();
  private dataSource: VaFilteredMatTableDataSource<T>;
  private filters: FilterGroup;

  private readonly selectedRows$$ = new BehaviorSubject<T[]>([]);
  selectedRows$: Observable<T[]> = this.selectedRows$$.asObservable();

  readonly displayedRows$$ = new BehaviorSubject<T[]>([]);
  displayedRows$: Observable<T[]> = this.displayedRows$$.asObservable();

  constructor(
    private readonly sortService: VaTableSortService,
    @Inject(TABLE_DEFINITION) readonly tableDefinition: TableDefinition,
  ) {
    this.filters = this.tableDefinition.filters
      ? this.tableDefinition.filters
      : new FilterGroup(this.tableDefinition.id);
  }

  init(): void {
    this.subscriptions.add(
      combineLatest([this.tableDefinition.filters.valueChanges, this.sortService.sortSettings$]).pipe(
        debounceTime(500),
      ),
      () => {
        this.resetPaging();
      },
    );
    this.subscriptions.add(this.paging$.pipe(skip(1)), () => this.load());
  }

  resetPaging(): void {
    const paging = this.paging$$.getValue();
    paging.pageIndex = 0;
    this.paging$$.next(paging);
  }

  load(): void {
    if (!this.dataSource) {
      // filters / sorting has fired before dataSource has been registered. Ignore these events.
      return;
    }
    this.dataSource.load(
      this.filters.value,
      this.sortService.value(),
      this.paging$$.value.pageIndex,
      this.paging$$.value.pageSize,
    );
  }

  registerDataSource(dataSource: VaFilteredMatTableDataSource<T>): void {
    this.dataSource = dataSource;
  }

  updatePaging(pageEvent: PageEvent): void {
    this.paging$$.next(pageEvent);
  }

  updateSelectedRows(rows: T[]): void {
    this.selectedRows$$.next(rows);
  }

  updateDisplayedRows(rows: T[]): void {
    this.displayedRows$$.next(rows);
  }

  ngOnDestroy(): void {
    this.subscriptions.destroy();
  }
}
