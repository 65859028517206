<div>
  <mat-form-field *ngIf="presets?.length > 0" subscriptSizing="dynamic" appearance="outline">
    <mat-label>Views</mat-label>
    <mat-select (selectionChange)="onSelectPreset($event.value)" [(value)]="presetService.selectedPreset">
      <mat-option (click)="onAllAccountsSelected()">All</mat-option>
      <mat-option *ngFor="let option of presets" matTooltip="{{ option.viewDescription }}" [value]="option.viewId">
        {{ option.viewName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
