import { Component, HostBinding, HostListener, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatColumnDef } from '@angular/material/table';
import { map } from 'rxjs/operators';
import { SortSetting, VaTableSortService } from '../va-table-sort.service';
import { SortDirection } from '../../va-table-definition/va-table-definition';
import { SubscriptionList } from '@vendasta/rx-utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[va-table-sort-header]',
  templateUrl: './va-table-sort-header.component.html',
  styleUrls: ['./va-table-sort-header.component.scss'],
})
export class VaTableSortHeaderComponent implements OnDestroy {
  SortDirection = SortDirection; // Allows you to reference enum in HTML template
  isSortable: boolean;
  columnId: string;
  @HostBinding('attr.role') role = 'button';
  public readonly sortDirection$$ = new BehaviorSubject<SortDirection>(SortDirection.SORT_DIRECTION_UNSET);
  private readonly subscriptions = SubscriptionList.new();

  constructor(private readonly columnDef: MatColumnDef, private readonly sortService: VaTableSortService) {
    this.columnId = this.columnDef.name;
    this.isSortable = sortService.isColumnSortable(this.columnId);
    if (!this.isSortable) {
      return;
    }

    this.subscriptions.add(
      this.sortService.sortSettings$.pipe(map((sortSetting) => sortSetting.filter((s) => s.id === this.columnId))),
      (sortSettings: SortSetting[]) => {
        this.sortDirection$$.next(
          sortSettings.length > 0 ? sortSettings[0].direction : SortDirection.SORT_DIRECTION_UNSET,
        );
      },
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.destroy();
  }

  @HostListener('click') toggleSort(): void {
    if (!this.isSortable) {
      return;
    }

    if (this.sortDirection$$.value === SortDirection.SORT_DIRECTION_UNSET) {
      this.sortDirection$$.next(SortDirection.SORT_DIRECTION_DESCENDING);
    } else if (this.sortDirection$$.value === SortDirection.SORT_DIRECTION_DESCENDING) {
      this.sortDirection$$.next(SortDirection.SORT_DIRECTION_ASCENDING);
    } else {
      this.sortDirection$$.next(SortDirection.SORT_DIRECTION_UNSET);
    }
    this.sortService.setToSingleColumnSort(this.columnId, this.sortDirection$$.value);
  }
}
