<script src="cell-value.service.ts"></script>
<table mat-table [dataSource]="dataSource" multiTemplateDataRows>
  <ng-container *ngIf="tableDefinition.options.selectableRows" matColumnDef="select" [sticky]="true">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? toggleAllRows() : null"
        [checked]="(selectedRows$$ | async)?.length > 0 && isAllSelected()"
        [indeterminate]="(selectedRows$$ | async)?.length > 0 && !isAllSelected()"
      ></mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? toggleSelection(row) : null"
        [checked]="isSelected(row)"
      ></mat-checkbox>
    </td>
  </ng-container>

  <ng-container *ngIf="tableDefinition.detailRow?.expandable" matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef class="expansion-button-cell"></th>
    <td mat-cell *matCellDef="let row" class="expansion-button-cell">
      <div *ngIf="tableDefinition.detailRow?.showDetailRow(row)">
        <button mat-icon-button (click)="handleExpandChange(row)">
          <mat-icon>
            {{ currentlyExpandedRow === row ? 'expand_less' : 'expand_more' }}
          </mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <ng-container
    *ngFor="let column of tableDefinition.columns"
    [matColumnDef]="column.id"
    [sticky]="column.pinned === Pinned.PINNED_LEFT"
    [stickyEnd]="column.pinned === Pinned.PINNED_RIGHT"
  >
    <th mat-header-cell va-table-sort-header *matHeaderCellDef [ngClass]="'mat-column-' + column.id + '-header'">
      <div *ngIf="column.headerComponent; else defaultHeader">
        <va-model-driven-mat-table-header [columnDefinition]="column"></va-model-driven-mat-table-header>
      </div>
      <ng-template #defaultHeader>
        {{ column.displayName | translate }}
      </ng-template>
    </th>
    <td
      mat-cell
      *matCellDef="let element"
      (click)="dataClickedWithEvent(element, $event)"
      [ngClass]="'mat-column-' + column.id + '-cell ' + !!column.width ? column.width : ColumnWidth.WIDTH_DEFAULT"
    >
      <div *ngIf="column.cellComponent; else simpleRow">
        <va-model-driven-mat-table-cell
          #cellComponent
          [columnDefinition]="column"
          [element]="element"
          [translator]="translateService"
          [eventEmitter]="cellEvent"
        >
          {{ getCellValue(column.id, column.field, element) }}
        </va-model-driven-mat-table-cell>
      </div>
      <ng-template #simpleRow>
        <div *ngIf="column.translateCell; else noTranslation">
          {{ getCellValue(column.id, column.field, element) | translate }}
        </div>
        <ng-template #noTranslation>
          {{ getCellValue(column.id, column.field, element) }}
        </ng-template>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="detailRow" *ngIf="tableDefinition.detailRow as detailRow">
    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="detail-row-cell">
      <div *ngIf="detailRow.column.cellComponent; else simpleRow">
        <va-model-driven-mat-table-cell
          [columnDefinition]="detailRow.column"
          [element]="element"
          [translator]="translateService"
          [eventEmitter]="cellEvent"
        >
          {{ getCellValue(detailRow.column.id, detailRow.column.field, element) }}
        </va-model-driven-mat-table-cell>
      </div>
      <ng-template #simpleRow>
        <div *ngIf="detailRow.column.translateCell; else noTranslation">
          {{ getCellValue(detailRow.column.id, detailRow.column.field, element) | translate }}
        </div>
        <ng-template #noTranslation>
          {{ getCellValue(detailRow.column.id, detailRow.column.field, element) }}
        </ng-template>
      </ng-template>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClicked(row)"></tr>
  <ng-container *ngIf="tableDefinition.detailRow">
    <tr
      mat-row
      *matRowDef="let row; columns: ['detailRow']"
      [ngStyle]="{
        display:
          (!this.tableDefinition.detailRow?.expandable || currentlyExpandedRow === row) &&
          tableDefinition.detailRow?.showDetailRow(row)
            ? 'table-row'
            : 'none'
      }"
    ></tr>
  </ng-container>
</table>
