<button
  mat-stroked-button
  class="sort-button"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="toggleSortConfigPopup()"
>
  <mat-icon>sort</mat-icon>
  Sort
</button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="showSortConfig"
  [cdkConnectedOverlayPositions]="[
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top'
    },
    { originX: 'end', originY: 'bottom', overlayX: 'center', overlayY: 'top' }
  ]"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayViewportMargin]="4"
  (backdropClick)="cancel()"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  cdkConnectedOverlayHasBackdrop
>
  <mat-card appearance="outlined" class="sort-config-overlay mat-elevation-z4">
    <ng-container *ngIf="(sortedColumns$ | async).length > 0; else nothingSorted">
      <div class="sorted-columns-container" cdkDropList (cdkDropListDropped)="drop($event)" cdkDragLockAxis="y">
        <!-- Sortable Column Item -->
        <ng-container *ngFor="let column of sortedColumns$ | async; first as isFirst">
          <div class="draggable-row" cdkDrag>
            <!-- Drag Icon -->
            <div class="drag-icon drag-cursor" cdkDragHandle>
              <mat-icon>drag_indicator</mat-icon>
            </div>

            <!-- Column Name and Sort Text -->
            <div class="name drag-cursor" cdkDragHandle>
              <span class="sort-text">
                <ng-container *ngIf="isFirst">
                  {{ 'Sort by' | translate }}
                  <!-- TODO: add an import here -->
                </ng-container>
                <ng-container *ngIf="!isFirst">
                  {{ 'Then by' | translate }}
                  <!-- TODO: add an import here -->
                </ng-container>
              </span>

              <span class="column-name">
                {{ column.displayName | translate }}
              </span>
            </div>

            <!-- Ascending/Descending Toggles -->
            <mat-button-toggle-group class="toggle-group">
              <mat-button-toggle
                [checked]="column.direction === SortDirection.SORT_DIRECTION_ASCENDING"
                (click)="setAcendingSort(column.id)"
              >
                <mat-icon class="sort-order-icon">arrow_upward</mat-icon>
                <span class="hide-on-mobile">
                  {{ column.ascendingLabel | translate }}
                </span>
              </mat-button-toggle>

              <mat-button-toggle
                [checked]="column.direction === SortDirection.SORT_DIRECTION_DESCENDING"
                (click)="setDecendingSort(column.id)"
              >
                <mat-icon class="sort-order-icon">arrow_downward</mat-icon>
                <span class="hide-on-mobile">
                  {{ column.descendingLabel | translate }}
                </span>
              </mat-button-toggle>
            </mat-button-toggle-group>

            <!-- Remove Sort -->
            <div class="remove-icon">
              <button mat-icon-button (click)="setUnsorted(column.id)">
                <mat-icon aria-label="Remove this sorting option">clear</mat-icon>
              </button>
            </div>

            <div class="drag-placeholder" *cdkDragPlaceholder></div>
          </div>
        </ng-container>

        <!-- Add new Sortable Column Selector -->
        <div class="sorted-select">
          <mat-select
            class="sorted-select-select"
            [(value)]="selectedColumnId"
            placeholder="{{ 'Add Another Column To Sort By' | translate }}"
            (selectionChange)="columnSelected($event.value)"
          >
            <!-- TODO: add an import here -->
            <mat-option class="sorted-select-option">
              {{ 'None' | translate }}
              <!-- TODO: add an import here -->
            </mat-option>

            <ng-container *ngFor="let column of unsortedColumns$ | async">
              <mat-option class="sorted-select-option" [value]="column.id">
                {{ column.displayName | translate }}
              </mat-option>
            </ng-container>
          </mat-select>
        </div>
      </div>
    </ng-container>

    <!-- Empty State -->
    <ng-template #nothingSorted>
      <div class="sorted-columns-container empty-state">
        <mat-select
          class="sorted-select-select"
          [(value)]="selectedColumnId"
          placeholder="{{ 'Choose a column to sort by' | translate }}"
          (selectionChange)="columnSelected($event.value)"
        >
          <!-- TODO: add an import here -->
          <mat-option class="sorted-select-option">
            {{ 'None' | translate }}
            <!-- TODO: add an import here -->
          </mat-option>

          <ng-container *ngFor="let column of unsortedColumns$ | async">
            <mat-option class="sorted-select-option" [value]="column.id">
              {{ column.displayName | translate }}
            </mat-option>
          </ng-container>
        </mat-select>
      </div>
    </ng-template>

    <!-- Apply and Cancel Buttons -->
    <div class="sort-config-actions">
      <button mat-stroked-button (click)="cancel()">
        {{ 'Cancel' | translate }}
        <!-- TODO: add an import here -->
      </button>
      <button mat-flat-button color="primary" (click)="applySortSettings()">
        {{ 'Update' | translate }}
        <!-- TODO: add an import here -->
      </button>
    </div>
  </mat-card>
</ng-template>
