<ng-template #content><ng-content></ng-content></ng-template>
<div *ngIf="isSortable; else noSorting" class="sort-header-container">
  <button class="sort-header-button">
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <mat-icon
      *ngIf="
        SortDirection.SORT_DIRECTION_DESCENDING === (sortDirection$$ | async)
      "
      class="sort-header-indicator"
    >
      arrow_downward
    </mat-icon>
    <mat-icon
      *ngIf="
        SortDirection.SORT_DIRECTION_ASCENDING === (sortDirection$$ | async)
      "
      class="sort-header-indicator"
    >
      arrow_upward
    </mat-icon>
    <mat-icon
      *ngIf="SortDirection.SORT_DIRECTION_UNSET === (sortDirection$$ | async)"
      class="sort-header-indicator"
    >
      unfold_more
    </mat-icon>
  </button>
</div>
<ng-template #noSorting>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-template>
