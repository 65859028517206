import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { SwitchingWorkState } from '@vendasta/rx-utils/work-state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortSetting } from '../va-table-sort/va-table-sort.service';

export interface LoadRequest {
  filters: any;
  sortingOptions: SortSetting[];
  pageIndex: number;
  pageSize: number;
}

export interface ExportToCSVRequest {
  filters: any;
  sortingOptions: SortSetting[];
  displayedColumns: string[];
}

export interface CustomExportEvent {
  requestInfo: ExportToCSVRequest;
  optionId: string;
}

export interface DataSourceSettings {
  filters: any;
  sortingOptions: SortSetting[];
  displayedColumns: string[];
}

export interface BulkActionClickedEvent {
  bulkActionID: string;
  currentSettings: DataSourceSettings;
}

export interface TableDataService<T> {
  totalResults$: Observable<number>;
  load(request: LoadRequest): Observable<T[]>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ExportToCSVService<T> {
  exportAllRowsToCSV(request: ExportToCSVRequest): void;
}

export class VaFilteredMatTableDataSource<T> implements DataSource<T> {
  private readonly workState = new SwitchingWorkState<LoadRequest, T[]>((request) => this.doWork(request));
  public readonly loading$ = this.workState.isLoading$;
  public noData$: Observable<boolean>;

  constructor(private readonly tableDataService: TableDataService<T>) {}

  load(filters: any, sortingOptions: SortSetting[], pageIndex: number, pageSize: number): void {
    this.workState.startWork({
      filters: filters,
      sortingOptions: sortingOptions,
      pageIndex: pageIndex,
      pageSize: pageSize,
    });
  }

  doWork(input: LoadRequest): Observable<T[]> {
    return this.tableDataService.load(input);
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    if (collectionViewer) {
      // no-op
    }
    this.noData$ = this.workState.workResults$.pipe(
      map((d) => {
        return d.length === 0;
      }),
    );
    return this.workState.workResults$;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    if (collectionViewer) {
      // no-op
    }
  }

  destroy(): void {
    this.workState.destroy();
  }
}
