import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PresetView, VaPresetViewService } from './va-preset-view.service';

@Component({
  selector: 'va-preset-view',
  templateUrl: './va-preset-view.component.html',
  styleUrls: ['./va-preset-view.component.scss'],
})
export class VaPresetViewComponent implements OnInit, OnDestroy {
  private _presetViews = new BehaviorSubject<string>('');

  @Input()
  set presetViews(jsonData: string) {
    this._presetViews.next(jsonData);
  }

  get presetViews(): string {
    return this._presetViews.getValue();
  }

  @Input()
  selectedPreset: string;

  @Output()
  filtersFromPreset = new EventEmitter();

  @Output()
  sortsFromPreset = new EventEmitter();

  presets: PresetView[];

  constructor(public readonly presetService: VaPresetViewService) {}

  ngOnInit(): void {
    this._presetViews.subscribe((x) => {
      this.presetService.setPresetViewsFromJson(x);
      this.presets = this.presetService.presets;
    });
  }

  ngOnDestroy(): void {
    this._presetViews.unsubscribe();
  }

  onAllAccountsSelected(): void {
    this.filtersFromPreset.emit({});
    this.sortsFromPreset.emit([]);
  }

  onSelectPreset(selectedPreset: string): void {
    if (selectedPreset) {
      const preset = this.presets.find((x) => x.viewId === selectedPreset);
      this.filtersFromPreset.emit(this.presetService.getFiltersFromPreset(preset.attributeFiltersJson));
      this.sortsFromPreset.emit(this.presetService.getSortsFromPreset(preset.sortingJson));
    }
  }
}
