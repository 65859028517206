{
  "FRONTEND": {
    "VA_FILTER": {
      "ITEMS_PER_PAGE": "Items per page",
      "NEXT_PAGE": "Next page",
      "PREVIOUS_PAGE": "Previous page",
      "OF": "of"
    }
  },
  "EXPORT": {
    "HEADER": "Generate CSV file",
    "INFORMATIVE_TEXT_LINE": "What data would you like to export?",
    "VISIBLE_OPTION_LABEL": "Visible data",
    "SELECTED_OPTION_LABEL": "Selected rows, all columns",
    "ALL_OPTION_LABEL": "All data",
    "DOWNLOAD_BUTTON": "Generate CSV file",
    "CANCEL_BUTTON": "Cancel",
    "NUMBER_OF_ROWS_PLURAL": "{{ number_of_rows }} rows",
    "NUMBER_OF_ROWS_SINGULAR": "1 row",
    "DEFAULT_DESCRIPTION": "Export results to a CSV file",
    "DEFAULT_BUTTON_TEXT": "Download"
  },
  "ACTIONS": {
    "DEFAULT_BUTTON_TEXT": "Actions"
  }
}
