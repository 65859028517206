import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnDefinition, CustomCellComponent } from '../va-table-definition/va-table-definition';
import { TableCellRefDirective } from './table-cell-ref.directive';

@Component({
  selector: 'va-model-driven-mat-table-header',
  template: `
    <ng-template va-table-cell-ref></ng-template>
  `,
})
export class TableHeaderComponent<T> implements OnInit {
  @Input() columnDefinition: ColumnDefinition;

  @ViewChild(TableCellRefDirective, { static: true }) cellDirective: TableCellRefDirective;

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.columnDefinition.headerComponent,
    );

    const viewContainerRef = this.cellDirective.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    const component = <CustomCellComponent<T>>componentRef.instance;
    component.columnDefinition = this.columnDefinition;
    component.translateService = this.translateService;
  }
}
