import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { TableCellRefDirective } from './table-cell-ref.directive';
import { MatColumnDef } from '@angular/material/table';
import { SubscriptionList } from '@vendasta/rx-utils';
import { ColumnDefinition, CustomCellComponent } from '../va-table-definition/va-table-definition';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'va-model-driven-mat-table-cell',
  template: `
    <ng-template va-table-cell-ref></ng-template>
  `,
})
export class TableCellComponent<T> implements OnInit {
  @Input() columnDefinition: ColumnDefinition;

  @Input() element: T;
  @Input() translator: TranslateService;
  @Input() eventEmitter: EventEmitter<any>;

  @ViewChild(TableCellRefDirective, { static: true }) cellDirective: TableCellRefDirective;

  component: CustomCellComponent<T>;

  private readonly subscriptions = SubscriptionList.new();

  constructor(
    private readonly columnDef: MatColumnDef,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnInit(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.columnDefinition.cellComponent);

    const viewContainerRef = this.cellDirective.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    this.component = <CustomCellComponent<T>>componentRef.instance;

    this.component.columnDefinition = this.columnDefinition;
    this.component.element = this.element;
    this.component.translateService = this.translator;
    this.component.eventEmitter = this.eventEmitter;
  }
}
