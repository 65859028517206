import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { SortSetting, VaTableSortService } from './va-table-sort.service';
import { SortDirection } from '../va-table-definition';

@Component({
  selector: 'va-table-sort',
  templateUrl: './va-table-sort.component.html',
  styleUrls: ['./va-table-sort.component.scss'],
})
export class VaTableSortComponent {
  public showSortConfig = false;

  public readonly sortedColumns$: Observable<SortSetting[]>;
  public readonly unsortedColumns$: Observable<SortSetting[]>;

  selectedColumnId: string = null;

  SortDirection = SortDirection;

  constructor(private readonly sortService: VaTableSortService) {
    this.sortedColumns$ = this.sortService.sortedColumns$;
    this.unsortedColumns$ = this.sortService.unsortedColumns$;
  }

  toggleSortConfigPopup(): void {
    if (this.showSortConfig) {
      this.cancel();
    } else {
      this.showSortConfig = true;
    }
  }

  applySortSettings(): void {
    this.sortService.applySortSettings();
    this.showSortConfig = false;
  }

  cancel(): void {
    this.showSortConfig = false;
  }

  columnSelected(columnId: string): void {
    this.sortService.addColumnToSorted(columnId);
    this.selectedColumnId = null;
  }

  drop(event: CdkDragDrop<SortSetting[]>): void {
    this.sortService.moveInSorted(event.previousIndex, event.currentIndex);
  }

  setDecendingSort(columnId: string): void {
    this.sortService.updatedSortedColumnToDescending(columnId);
  }

  setAcendingSort(columnId: string): void {
    this.sortService.updatedSortedColumnToAscending(columnId);
  }

  setUnsorted(columnId: string): void {
    this.sortService.removeColumnFromSorted(columnId);
  }
}
