import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ColumnDefinition,
  ColumnSortingLabels,
  ColumnType,
  SortDirection,
  SortingLabels,
  TABLE_DEFINITION,
  TableDefinition,
} from '../va-table-definition/va-table-definition';

export interface SortSetting {
  id: string;
  field: string;
  displayName: string;
  direction: SortDirection;
  ascendingLabel: string;
  descendingLabel: string;
}

@Injectable()
export class VaTableSortService {
  private readonly sortedColumns$$ = new BehaviorSubject<SortSetting[]>(this.initialSortSettings);
  public readonly sortedColumns$ = this.sortedColumns$$.asObservable();

  private readonly unsortedColumns$$ = new BehaviorSubject<SortSetting[]>(this.initialSortSettingsUnsorted);
  public readonly unsortedColumns$ = this.unsortedColumns$$.asObservable();

  private readonly sortSettings$$ = new BehaviorSubject<SortSetting[]>(this.initialSortSettings);
  public readonly sortSettings$ = this.sortSettings$$.asObservable();

  private static columnTypeToSortLabels(labels: ColumnSortingLabels, type: ColumnType | undefined): SortingLabels {
    switch (type) {
      case ColumnType.COLUMN_TYPE_DATE:
        return labels.date;
      case ColumnType.COLUMN_TYPE_NUMBER:
        return labels.number;
      case ColumnType.COLUMN_TYPE_STRING:
        return labels.string;
      default:
        return labels.default;
    }
  }

  constructor(@Inject(TABLE_DEFINITION) private readonly tableDefinition: TableDefinition) {}

  private get sortableColumnIDs(): string[] {
    return this.sortableColumns.map((c) => c.id);
  }

  public get sortableColumns(): ColumnDefinition[] {
    return this.tableDefinition.columns.filter((c) => c.sortOptions && c.sortOptions.sortable);
  }

  public get initialSortSettings(): SortSetting[] {
    return this.columnDefinitionsToSortSettings(
      this.tableDefinition.labels.columnSorting,
      this.sortableColumns.filter(
        (c) =>
          c.sortOptions !== undefined &&
          c.sortOptions.initialSortDirection !== undefined &&
          c.sortOptions.initialSortDirection !== SortDirection.SORT_DIRECTION_UNSET,
      ),
    );
  }

  private get initialSortSettingsUnsorted(): SortSetting[] {
    return this.columnDefinitionsToSortSettings(
      this.tableDefinition.labels.columnSorting,
      this.sortableColumns.filter((c) => !this.initialSortSettings.map((s) => s.id).includes(c.id)),
    );
  }

  private columnDefinitionsToSortSettings(labels: ColumnSortingLabels, config: ColumnDefinition[]): SortSetting[] {
    return config.map(
      (col) =>
        ({
          id: col.id,
          field: col.field ? col.field : col.id,
          direction: col.sortOptions ? col.sortOptions.initialSortDirection : SortDirection.SORT_DIRECTION_ASCENDING,
          displayName: col.displayName,
          ascendingLabel: VaTableSortService.columnTypeToSortLabels(labels, col.type).ascending,
          descendingLabel: VaTableSortService.columnTypeToSortLabels(labels, col.type).descending,
        } as SortSetting),
    );
  }

  public applySortSettings(): void {
    const sort = this.sortedColumns$$.value;
    this.sortSettings$$.next(sort);
  }

  public setSortSettings(sortSettings: SortSetting[]): void {
    const sorted = this.sortedColumns$$.value;
    const unsorted = this.unsortedColumns$$.value;
    this.removeAllSorted(sorted, unsorted);

    sortSettings.forEach((sort) => {
      if (sort.direction !== SortDirection.SORT_DIRECTION_UNSET) {
        this.addColumnToSorted(sort.id);
        this.setSortDirection(sort.direction, sort.id);
      }
    });

    this.sortedColumns$$.next(sorted);
    this.unsortedColumns$$.next(unsorted);
    this.sortSettings$$.next(sorted);
  }

  addColumnToSorted(columnId: string): void {
    const sorted = this.sortedColumns$$.value;
    const unsorted = this.unsortedColumns$$.value;
    unsorted.forEach((column, i) => {
      if (column.id === columnId) {
        column.direction = SortDirection.SORT_DIRECTION_DESCENDING;
        sorted.push(column);
        unsorted.splice(i, 1);
      }
    });
    this.unsortedColumns$$.next(unsorted);
    this.sortedColumns$$.next(sorted);
  }

  isColumnSortable(id: string): boolean {
    return this.sortableColumnIDs.find((c) => c === id) !== undefined;
  }

  moveInSorted(previousIndex: number, currentIndex: number): void {
    const sorted = this.sortedColumns$$.value;
    moveItemInArray(sorted, previousIndex, currentIndex);
    this.sortedColumns$$.next(sorted);
  }

  setToSingleColumnSort(id: string, direction: SortDirection): void {
    const sorted = this.sortedColumns$$.value;
    const unsorted = this.unsortedColumns$$.value;
    this.removeAllSorted(sorted, unsorted);
    if (direction !== SortDirection.SORT_DIRECTION_UNSET) {
      this.addColumnToSorted(id);
      this.setSortDirection(direction, id);
    }
    this.unsortedColumns$$.next(unsorted);
    this.sortedColumns$$.next(sorted);
    this.applySortSettings();
  }

  updatedSortedColumnToAscending(id: string): void {
    this.setSortDirection(SortDirection.SORT_DIRECTION_ASCENDING, id);
  }

  updatedSortedColumnToDescending(id: string): void {
    this.setSortDirection(SortDirection.SORT_DIRECTION_DESCENDING, id);
  }

  removeColumnFromSorted(id: string): void {
    const sorted = this.sortedColumns$$.value;
    const unsorted = this.unsortedColumns$$.value;
    sorted.forEach((column, i) => {
      if (column.id === id) {
        column.direction = SortDirection.SORT_DIRECTION_UNSET;
        unsorted.push(column);
        sorted.splice(i, 1);
      }
    });
    this.unsortedColumns$$.next(unsorted);
    this.sortedColumns$$.next(sorted);
  }

  removeAll(): void {
    const sorted = this.sortedColumns$$.value;
    const unsorted = this.unsortedColumns$$.value;
    this.removeAllSorted(sorted, unsorted);
  }

  addColumnWithSortDirection(columnId: string, sortingDirection: string): void {
    const sorted = this.sortedColumns$$.value;
    const unsorted = this.unsortedColumns$$.value;
    unsorted.forEach((column, i) => {
      if (column.id === columnId) {
        if (sortingDirection === 'asc') {
          column.direction = SortDirection.SORT_DIRECTION_ASCENDING;
        } else if (sortingDirection === 'desc') {
          column.direction = SortDirection.SORT_DIRECTION_DESCENDING;
        } else {
          column.direction = SortDirection.SORT_DIRECTION_UNSET;
        }
        sorted.push(column);
        unsorted.splice(i, 1);
      }
    });
    this.unsortedColumns$$.next(unsorted);
    this.sortedColumns$$.next(sorted);
  }

  value(): SortSetting[] {
    return this.sortSettings$$.value;
  }

  private removeAllSorted(sorted: SortSetting[], unsorted: SortSetting[]): void {
    for (let i = 0; i < sorted.length; i++) {
      const column = sorted[i];
      column.direction = SortDirection.SORT_DIRECTION_UNSET;
      unsorted.push(column);
    }
    sorted.splice(0, sorted.length);
  }

  private setSortDirection(direction: SortDirection, id: string): void {
    const sorted = this.sortedColumns$$.value;
    sorted.forEach((column) => {
      if (column.id !== id) {
        return;
      }
      column.direction = direction;
    });
    this.sortedColumns$$.next(sorted);
  }
}
