import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FilterGroup } from '@vendasta/va-filter2';
import moment from 'moment';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { CsvExporterService } from '../csv-exporter/csv-exporter.service';
import {
  CustomExportEvent,
  ExportToCSVRequest,
  ExportToCSVService,
  TableDataService,
} from '../va-filtered-mat-table/va-filtered-mat-table-data-source';
import { CellValueService } from '../va-model-driven-mat-table/cell-value.service';
import { TableCellComponent } from '../va-model-driven-mat-table/table-cell.component';
import { ColumnType, CsvExporterDialogInterface, TableDefinition } from '../va-table-definition/va-table-definition';
import { SortSetting } from '../va-table-sort/va-table-sort.service';

// @dynamic
@Component({
  selector: 'va-csv-exporter-dialog',
  templateUrl: './csv-exporter-dialog.component.html',
  styleUrls: ['./csv-exporter-dialog.component.scss'],
})
export class CsvExporterDialogComponent<T> implements OnInit {
  exportType = 'visible';
  dialogContent: CsvExporterDialogInterface;

  constructor(
    public dialogRef: MatDialogRef<CsvExporterDialogComponent<T>, CustomExportEvent>,
    private readonly csvExporterService: CsvExporterService,
    private readonly cellValueService: CellValueService<T>,
    private readonly translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      actionableRowsSize: number;
      tableDataService: TableDataService<T>;
      exportAllDataService: ExportToCSVService<T>;
      customCellComponents: TableCellComponent<T>[];
      tableDefinition: TableDefinition;
      displayedColumns: string[];
      filters: FilterGroup;
      sortOptions: SortSetting[];
      selectedRows$: Observable<T[]>;
      displayedRows$: Observable<T[]>;
      exportDialogDefinition: CsvExporterDialogInterface;
    },
  ) {}

  ngOnInit(): void {
    if (this.dialogData.exportDialogDefinition) {
      this.dialogContent = this.dialogData.exportDialogDefinition;
      // if there is overridden content, then use the first option as the pre-selected radio
      this.exportType = this.dialogData.exportDialogDefinition.exportOptions[0].id;
    } else {
      this.dialogContent = this.setDialogContent();
    }
  }

  getRadioOptionContent(rows: Observable<number>): string {
    let textForOption = '';
    rows
      .pipe(
        switchMap((numberOfRows) => {
          if (numberOfRows === 1) {
            return this.translate.stream('EXPORT.NUMBER_OF_ROWS_SINGULAR');
          }
          return this.translate.stream('EXPORT.NUMBER_OF_ROWS_PLURAL', { number_of_rows: numberOfRows.toString() });
        }),
      )
      .subscribe((text) => {
        textForOption = text;
      });
    return textForOption;
  }

  setDialogContent(): CsvExporterDialogInterface {
    let headerText = '';
    this.translate.stream('EXPORT.HEADER').subscribe((text) => {
      headerText = text;
    });
    const dialogContent: CsvExporterDialogInterface = {
      headerText: headerText,
    };
    this.translate.stream('EXPORT.INFORMATIVE_TEXT_LINE').subscribe((descriptionText) => {
      dialogContent.descriptionText = descriptionText;
    });

    // build the radio button options
    let visibleOptionText = '';
    this.translate.stream('EXPORT.VISIBLE_OPTION_LABEL').subscribe((text) => {
      visibleOptionText = text;
    });
    let lengthOfRows$ = this.dialogData.displayedRows$.pipe(map((displayedRows) => displayedRows.length));
    const extraVisibleOptionText = this.getRadioOptionContent(lengthOfRows$);
    dialogContent.exportOptions = [
      {
        id: 'visible',
        selectionText: visibleOptionText,
        extraSelectionText: ' | ' + extraVisibleOptionText,
      },
    ];

    let selectionOptionText = '';
    this.translate.stream('EXPORT.SELECTED_OPTION_LABEL').subscribe((text) => {
      selectionOptionText = text;
    });
    lengthOfRows$ = this.dialogData.selectedRows$.pipe(map((selectedRows) => selectedRows.length));
    const extraSelectedOptionText = this.getRadioOptionContent(lengthOfRows$);
    if (extraSelectedOptionText[0] !== '0') {
      dialogContent.exportOptions.push({
        id: 'selected',
        selectionText: selectionOptionText,
        extraSelectionText: ' | ' + extraSelectedOptionText,
      });
    }

    if (this.dialogData.exportAllDataService) {
      let exportAllOptionText = '';
      this.translate.stream('EXPORT.ALL_OPTION_LABEL').subscribe((text) => {
        exportAllOptionText = text;
      });
      const exportAllOptionExtraText = this.getRadioOptionContent(this.dialogData.tableDataService.totalResults$);
      dialogContent.exportOptions.push({
        id: 'all',
        selectionText: exportAllOptionText,
        extraSelectionText: ' | ' + exportAllOptionExtraText,
      });
    }

    return dialogContent;
  }

  export(): void {
    let customExportEvent = false;
    switch (this.exportType) {
      case 'all':
        this.exportAll();
        break;
      case 'visible':
        this.exportVisible();
        break;
      case 'selected':
        this.exportSelected();
        break;
      default:
        // in the case that a custom export type was specified, emit the filters and the type of export
        customExportEvent = true;
        break;
    }
    this.close(customExportEvent);
  }

  exportAll(): void {
    this.csvExporterService.clearRows();

    const request: ExportToCSVRequest = {
      filters: this.dialogData.filters,
      sortingOptions: this.dialogData.sortOptions,
      displayedColumns: this.dialogData.displayedColumns,
    };
    this.dialogData.exportAllDataService.exportAllRowsToCSV(request);
  }

  exportVisible(): void {
    this.csvExporterService.clearRows();
    this.exportRows(this.dialogData.displayedRows$);
  }

  exportSelected(): void {
    this.csvExporterService.clearRows();
    this.exportRows(this.dialogData.selectedRows$);
  }

  exportRows(rowsToExport: Observable<T[]>): void {
    rowsToExport.pipe(first()).subscribe((rows) => {
      this.csvExporterService.setTitleRow(this.getTitleRow());

      rows.forEach((row) => {
        const rowData: string[] = this.rowToExportRow(row);
        this.csvExporterService.addDataRow(rowData);
      });
      const now = moment().format('L');
      this.csvExporterService.downloadFile(`export-${this.dialogData.tableDefinition.id}-${now}.csv`);
    });
  }

  rowToExportRow(row: T): string[] {
    let value: string;
    const rowData: string[] = [];
    this.dialogData.tableDefinition.columns
      .filter((c) => this.dialogData.displayedColumns.includes(c.id))
      .forEach((c) => {
        const componentValue = this.cellValueService.getCellComponentValue(
          this.dialogData.customCellComponents,
          c,
          row,
        );
        value = componentValue;
        if (value === undefined || value === null || value === '') {
          value = this.cellValueService.getCellStringValue(c, row);
        }

        if (value && c.translateCell) {
          value = this.translate.instant(value);
        }
        if (!value && c.type === ColumnType.COLUMN_TYPE_NUMBER) {
          value = '0';
        }
        rowData.push(value);
      });
    return rowData;
  }

  getTitleRow(): string[] {
    const titleRow: string[] = [];
    this.dialogData.tableDefinition.columns
      .filter((c) => this.dialogData.displayedColumns.includes(c.id))
      .forEach((c) => {
        titleRow.push(this.translate.instant(c.displayName));
      });
    return titleRow;
  }

  close(customExportEvent?: boolean): void {
    if (customExportEvent) {
      const exportInfo: CustomExportEvent = {
        requestInfo: {
          filters: this.dialogData.filters,
          sortingOptions: this.dialogData.sortOptions,
          displayedColumns: this.dialogData.displayedColumns,
        },
        optionId: this.exportType,
      };
      this.dialogRef.close(exportInfo);
    } else {
      this.dialogRef.close();
    }
  }
}
