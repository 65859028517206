import { Injectable } from '@angular/core';

export class PresetView {
  viewId: string;
  viewName: string;
  viewDescription: string;
  attributeFiltersJson: string;
  sortingJson: string;
}

export class Sort {
  column: string;
  orientation: string;
}

@Injectable()
export class VaPresetViewService {
  presets: PresetView[];
  public selectedPreset: string;

  public setPresetViewsFromJson(presetJSON: string): void {
    if (presetJSON !== undefined) {
      try {
        this.presets = JSON.parse(presetJSON);
      } catch (e) {
        console.error('Error parsing presets JSON, setting as no presets: ' + e);
        this.presets = [];
      }
    }
  }

  public getFiltersFromPreset(attributeFiltersJson: string): any {
    let filterData: any = [];
    try {
      filterData = JSON.parse(attributeFiltersJson);
    } catch (e) {
      console.error('Error parsing attribute Filter JSON, setting as no filters: ' + e);
    }
    return filterData;
  }

  public getSortsFromPreset(sortingJson: string): Sort[] {
    let sortData: any = [];
    const sortList: Sort[] = [];
    try {
      sortData = JSON.parse(sortingJson);
    } catch (e) {
      console.error('Error parsing sorting JSON, setting as no sorts: ' + e);
    }
    Object.keys(sortData).forEach((sort) => {
      const sorting: Sort = {
        column: sort,
        orientation: sortData[sort],
      };
      sortList.push(sorting);
    });
    return sortList;
  }

  public setSelectedPreset(filters: any): void {
    this.selectedPreset = '';
    if (this.presets) {
      this.presets.forEach((preset) => {
        const presetFilters = this.getFiltersFromPreset(preset.attributeFiltersJson);
        if (this.filtersAreEqual(filters, presetFilters)) {
          this.selectedPreset = preset.viewId;
        }
      });
    }
  }

  private filtersAreEqual(tableFilters: any, presetFilters: any): boolean {
    let isEqual = true;
    Object.keys(tableFilters).forEach((tableFilterKey) => {
      if (tableFilterKey in presetFilters) {
        if (JSON.stringify(tableFilters[tableFilterKey]) !== JSON.stringify(presetFilters[tableFilterKey])) {
          isEqual = false;
        }
      } else {
        if (
          tableFilterKey in tableFilters &&
          tableFilters[tableFilterKey] !== null &&
          tableFilters[tableFilterKey] !== undefined &&
          Object.keys(tableFilters[tableFilterKey]).length
        ) {
          isEqual = false;
        }
      }
    });
    return isEqual;
  }
}
