import { Injectable } from '@angular/core';

@Injectable()
export class CsvExporterService {
  private titleRow: string[] = [];
  private dataRows: string[][] = [];

  setTitleRow(titleRow: string[]): void {
    this.titleRow = titleRow.map(this.formatCSVData);
  }

  addDataRow(dataRow: string[]): void {
    this.dataRows.push(dataRow.map(this.formatCSVData));
  }

  clearRows(titleRow = true, dataRows = true): void {
    if (titleRow) {
      this.titleRow = [];
    }
    if (dataRows) {
      this.dataRows = [];
    }
  }

  downloadFile(fileName: string): void {
    let content = this.titleRow.join(',') + '\n';
    content += this.dataRows
      .map((dataRow) => {
        return dataRow.join(',');
      })
      .join('\n');
    this.download(content, fileName);
  }

  private formatCSVData(data: string): string {
    if (!data) {
      return '';
    }
    data = String(data);
    data = data.replace(/"/g, '""');
    if (data.indexOf(',') > -1 || data.indexOf('\n') > -1 || data.indexOf('\r') > -1) {
      data = '"' + data + '"';
    }
    return data;
  }

  private download(content: string, fileName: string): void {
    const a = document.createElement('a');
    const mimeType = 'application/octet-stream';

    if (URL && 'download' in a) {
      a.href = URL.createObjectURL(
        new Blob([content], {
          type: mimeType,
        }),
      );
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
  }
}
