import { Injectable } from '@angular/core';
import { ColumnDefinition } from '../va-table-definition';
import { TableCellComponent } from './table-cell.component';

@Injectable({
  providedIn: 'root',
})
export class CellValueService<T> {
  getCellValue(id: string, field: string, element: T): any {
    let fullKey = field;
    if (fullKey === undefined) {
      fullKey = id;
    }
    if (fullKey === undefined) {
      return;
    }
    let val = element;
    const splitKeys = fullKey.split('.');
    for (let i = 0; i < splitKeys.length; ++i) {
      const key = splitKeys[i];
      if (key in (val as object)) {
        val = val[key];
      } else {
        return '';
      }
    }
    return val;
  }

  getCellStringValue(columnDef: ColumnDefinition, element: T): string {
    if (columnDef.toStringFunction) {
      return columnDef.toStringFunction(element);
    }
    const cellValue = this.getCellValue(columnDef.id, columnDef.field, element);
    if (cellValue === undefined) {
      return '';
    }
    let valueStr = String(cellValue);
    if (valueStr === '[object Object]') {
      valueStr = JSON.stringify(cellValue);
    }
    return valueStr;
  }

  getCellComponentValue(cellComponents: Array<TableCellComponent<T>>, columnDef: ColumnDefinition, element: T): string {
    const componentDefIndex = cellComponents.findIndex((c: TableCellComponent<T>) => {
      return (
        c.columnDefinition &&
        c.element &&
        c.columnDefinition.id === columnDef.id &&
        c.element[c.columnDefinition.field] === element[columnDef.field]
      );
    });

    if (componentDefIndex === -1) {
      return '';
    }

    const componentDef = cellComponents[componentDefIndex];

    const component = componentDef.component;
    if ('exportData' in component) {
      return component.exportData();
    }

    return null;
  }
}
