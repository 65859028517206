<div class="filter-container">
  <va-filter2-model [filterGroup]="filters" [title]="title | translate" [toolTip]="tooltip">
    <va-filter-toolbar-tooltip>
      <ng-content select="va-filtered-mat-table-tooltip"></ng-content>
    </va-filter-toolbar-tooltip>

    <va-filter-toolbar-actions>
      <div class="action-bar-items">
        <ng-content select="va-filtered-mat-table-actions"></ng-content>

        <va-preset-view
          [presetViews]="presetJSON"
          (filtersFromPreset)="handleFilterFromPreset($event)"
          (sortsFromPreset)="handleSortFromPreset($event)"
        ></va-preset-view>

        <va-table-sort *ngIf="displaySortButton"></va-table-sort>

        <!-- Customize Columns -->
        <ng-container *ngIf="showColumnSelector">
          <va-mat-table-column-sorter
            *ngIf="!tableDefinition.columnOrganizerType"
            [columnConfigs]="displayedSorterColumns"
            [saveName]="tableDefinition.id"
            [useI18NTranslations]="true"
            (columnsChange)="columnsChanged($event)"
            iconName="view_column"
            [buttonLabel]="tableDefinition.labels?.columnSelector?.buttonText | translate"
          ></va-mat-table-column-sorter>

          <va-mat-table-advanced-column-organizer-button
            *ngIf="tableDefinition.columnOrganizerType === columnOrganizerType.ADVANCED_ORGANIZER"
            [saveName]="tableDefinition.id"
            [columnConfigs]="tableColumns"
            [groupConfigs]="tableGroups"
            [useI18NTranslations]="true"
            (columnsChange)="columnsChanged($event)"
            (click)="handleColumnButtonClicked()"
          >
            <div class="centered-label">
              <mat-icon>view_column</mat-icon>
              {{ tableDefinition.labels?.columnSelector?.buttonText | translate }}
            </div>
          </va-mat-table-advanced-column-organizer-button>
        </ng-container>

        <button
          *ngIf="showBulkActions"
          [matMenuTriggerFor]="bulkActionsMenu"
          mat-stroked-button
          class="dropdown-icon action-button"
          aria-label="dropdown for bulk actions"
        >
          <mat-icon>flash_on</mat-icon>
          {{ actionsButtonText | translate }}
          <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #bulkActionsMenu="matMenu">
          <div class="bulk-actions-menu" *ngIf="showBulkActions">
            <button
              *ngFor="let bulkAction of allActions"
              (click)="bulkActionMenuItemClicked(bulkAction.actionId)"
              mat-menu-item
              class="bulk-actions-menu-item multi-line-menu"
            >
              <div class="icon">
                <mat-icon>{{ bulkAction.actionButtonMaterialIcon }}</mat-icon>
              </div>
              <div class="action-button-description">
                <div>{{ bulkAction.actionButtonText | translate }}</div>
                <div *ngIf="bulkAction.description">
                  <small class="action-description">
                    {{ bulkAction.description | translate }}
                  </small>
                </div>
              </div>
            </button>
          </div>
        </mat-menu>
      </div>
    </va-filter-toolbar-actions>
    <va-filter-content>
      <div
        class="table-loading--wrapper"
        [ngClass]="{
          'table-is-loading': dataSource.loading$ | async,
          'full-height': fullHeightTable
        }"
      >
        <div id="scroll-wrapper" [ngClass]="{ 'limit-height': limitContentHeightForDesktop }">
          <va-model-driven-mat-table
            #table
            [dataSource]="dataSource"
            [tableDefinition]="tableDefinition"
            [displayedColumns]="displayedColumns"
            [preselectedRows]="preselectedRows"
            (rowSelection)="updateSelectedRows($event)"
            (allRows)="updateDisplayedRows($event)"
            (cellEvent)="cellEventEmitted($event)"
            (rowClick)="rowClicked($event)"
            (dataClickWithEvent)="dataClickedWithEvent($event)"
          ></va-model-driven-mat-table>
        </div>

        <div class="loading-message">
          <div class="loading-message--text">
            <mat-spinner [diameter]="32" *ngIf="dataSource.loading$ | async"></mat-spinner>
          </div>
        </div>

        <div *ngIf="dataSource.noData$ | async">
          <ng-container *ngIf="!searchEmptyStateTemplate && !initEmptyStateTemplate; else emptyTemplate">
            <div class="no-data">
              {{ tableDefinition.labels?.noResults | translate }}
            </div>
          </ng-container>

          <ng-template #emptyTemplate>
            <ng-container *ngIf="filters.value.search; else initEmptyTemplate">
              <ng-container *ngTemplateOutlet="searchEmptyStateTemplate"></ng-container>
            </ng-container>
            <ng-template #initEmptyTemplate>
              <ng-container *ngTemplateOutlet="initEmptyStateTemplate"></ng-container>
            </ng-template>
          </ng-template>
        </div>
      </div>

      <mat-paginator
        [length]="tableDataService.totalResults$ | async"
        [pageSize]="defaultPageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="onPage($event)"
      ></mat-paginator>
    </va-filter-content>
  </va-filter2-model>
</div>
