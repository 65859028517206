<h2 mat-dialog-title>
  <span>{{ dialogContent.headerText }}</span>
</h2>
<mat-dialog-content>
  <p>{{ dialogContent.descriptionText }}</p>
  <div *ngFor="let options of dialogContent.exportOptions">
    <p>
      <mat-radio-group [(ngModel)]="exportType">
        <mat-radio-button value="{{ options.id }}">
          <strong>{{ options.selectionText }}</strong>
          {{ options.extraSelectionText }}
        </mat-radio-button>
        <mat-hint *ngIf="options.selectionHelpText">
          {{ options.selectionHelpText }}
        </mat-hint>
      </mat-radio-group>
    </p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" (click)="close()">
    {{ 'EXPORT.CANCEL_BUTTON' | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    *ngIf="dialogContent.exportOptions?.length > 0"
    (click)="export()"
  >
    {{ 'EXPORT.DOWNLOAD_BUTTON' | translate }}
  </button>
</mat-dialog-actions>
