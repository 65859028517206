import {
  AfterViewInit,
  Component,
  Directive,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionList } from '@vendasta/rx-utils';
import { ColumnSorterColumn } from '@vendasta/uikit';
import { FilterGroup } from '@vendasta/va-filter2';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CsvExporterDialogComponent } from '../csv-exporter/csv-exporter-dialog.component';
import { VaModelDrivenMatTableComponent } from '../va-model-driven-mat-table';
import { Sort, VaPresetViewService } from '../va-preset-view/va-preset-view.service';
import {
  BulkActionsOptionsInterface,
  ColumnDefinition,
  ColumnOrganizerType,
  GroupDefinition,
  Pinned,
  TABLE_DEFINITION,
  TableDefinition,
} from '../va-table-definition/va-table-definition';
import { VaTableSortService } from '../va-table-sort/va-table-sort.service';
import {
  BulkActionClickedEvent,
  CustomExportEvent,
  ExportToCSVRequest,
  ExportToCSVService,
  TableDataService,
  VaFilteredMatTableDataSource,
} from './va-filtered-mat-table-data-source';
import { VaFilteredMatTableService } from './va-filtered-mat-table.service';

export interface ColumnsChangedEvent {
  tableId: string;
  columns: string[];
}

@Directive({
  selector: 'va-filtered-mat-table-actions, [va-filtered-mat-table-actions], [vaFilteredMatTableActions]',
})
export class VaFilteredMatTableActionsDirective {
  @HostBinding('class') className = 'va-filter-toolbar-actions';
}

@Directive({
  selector: 'va-filtered-mat-table-tooltip, [va-filtered-mat-table-tooltip], [vaFilteredMatTableTooltip]',
})
export class VaFilteredMatTableTooltipDirective {
  @HostBinding('class') className = 'va-filter-toolbar-tooltip';
}

@Component({
  selector: 'va-filtered-mat-table',
  templateUrl: './va-filtered-mat-table.component.html',
  styleUrls: ['./va-filtered-mat-table.component.scss'],
})
export class VaFilteredMatTableComponent<T> implements OnInit, OnDestroy, AfterViewInit {
  @Input() tableDataService: TableDataService<T>;
  @Input() exportAllDataService: ExportToCSVService<T>;
  @Input() presetJSON: string;
  @Input() preselectedRows: T[] = [];
  @Input() limitContentHeightForDesktop = true; // allows vertical scrolling of content inside table on desktop
  @Input() useCSVExportAsButton = false;
  @Input() fullHeightTable = false;
  @Input() searchEmptyStateTemplate: TemplateRef<any> = null;
  @Input() initEmptyStateTemplate: TemplateRef<any> = null;
  @Input() tooltip: string;

  @Output() columnsChangedEventEmitter = new EventEmitter<ColumnsChangedEvent>();
  @Output() cellEventEmitter = new EventEmitter<any>();
  // when the exportDialogDefinition is defined with an id other than 'selected', 'visible', or 'all', this will emit
  // the custom id that was selected and the relevant data
  @Output() customExportEvent = new EventEmitter<CustomExportEvent>();
  @Output() csvExportButtonClicked = new EventEmitter<ExportToCSVRequest>();
  @Output() columnButtonClicked = new EventEmitter<any>();
  @Output() rowClick = new EventEmitter<T>();
  @Output() dataClickWithEvent = new EventEmitter<T>();
  // non-CSV-export actions must be handled by the code using this table library
  // you pop the modal and get choices from the user,
  // then grab the visible/selected/all rows from the table-data service
  @Output() bulkActionClicked = new EventEmitter<BulkActionClickedEvent>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('table', { static: true }) table: VaModelDrivenMatTableComponent<T>;

  dataSource: VaFilteredMatTableDataSource<T>;

  pinnedLeftCols: string[] = this.tableDefinition.columns
    .filter((c) => c.pinned === Pinned.PINNED_LEFT)
    .map((c) => c.id);
  pinnedRightCols: string[] = this.tableDefinition.columns
    .filter((c) => c.pinned === Pinned.PINNED_RIGHT)
    .map((c) => c.id);

  displayedSorterColumns: ColumnSorterColumn[] = this.tableDefinition.columns
    .filter((c) => c.pinned !== Pinned.PINNED_LEFT && c.pinned !== Pinned.PINNED_RIGHT)
    .map((c) => {
      return {
        columnId: c.id,
        columnName: c.displayName,
        hidden: !!c.hidden,
      };
    });
  displayedColumns: string[] = [
    ...this.pinnedLeftCols,
    ...this.displayedSorterColumns.map((c) => c.columnId),
    ...this.pinnedRightCols,
  ];

  columnOrganizerType = ColumnOrganizerType;
  tableColumns: ColumnDefinition[] = this.tableDefinition.columns;
  tableGroups: GroupDefinition[] = this.tableDefinition.groups;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  defaultPageSize = 25;
  previousPageSize = this.defaultPageSize;

  private readonly subscriptions = SubscriptionList.new();

  readonly filters: FilterGroup = this.tableDefinition.filters
    ? this.tableDefinition.filters
    : new FilterGroup(this.tableDefinition.id);

  displaySortButton = true;

  actionableRows$: Observable<T[]>;

  readonly exportCSVBulkActionID = new Object();

  private readonly csvExportEnabled =
    this.tableDefinition.options?.csvExportEnabled ||
    this.tableDefinition.options?.exportDialogDefinition?.exportOptions?.length > 0;

  readonly actionsButtonText: string =
    this.tableDefinition.labels?.actionsButton || this.translate.instant('ACTIONS.DEFAULT_BUTTON_TEXT');

  readonly allActions: BulkActionsOptionsInterface[] = [].concat(
    this.csvExportEnabled
      ? [
          {
            actionButtonText:
              this.tableDefinition?.labels?.export?.buttonText || this.translate.instant('EXPORT.DEFAULT_BUTTON_TEXT'),
            actionButtonMaterialIcon: 'cloud_download',
            actionId: this.exportCSVBulkActionID,
            description:
              this.tableDefinition?.labels?.export?.description || this.translate.instant('EXPORT.DEFAULT_DESCRIPTION'),
          },
        ]
      : [],
    this.tableDefinition.options?.bulkActions || [],
  );
  readonly showBulkActions: boolean = this.allActions.length > 0;

  readonly showColumnSelector: boolean = !!this.tableDefinition.labels.columnSelector;

  title: string;

  constructor(
    private readonly sortService: VaTableSortService,
    @Inject(TABLE_DEFINITION) readonly tableDefinition: TableDefinition,
    private readonly vaFilteredTableService: VaFilteredMatTableService<T>,
    private readonly translate: TranslateService,
    private readonly presetService: VaPresetViewService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.dataSource = new VaFilteredMatTableDataSource(this.tableDataService);
    this.vaFilteredTableService.registerDataSource(this.dataSource);

    this.displaySortButton = this.sortService.sortableColumns.length > 0;

    if (this.tableDefinition.options) {
      if (this.tableDefinition.options.tableTitle) {
        this.title = this.tableDefinition.options.tableTitle;
      }
      if (this.tableDefinition.options.pageSizeOptions) {
        this.pageSizeOptions = this.tableDefinition.options.pageSizeOptions;
        this.defaultPageSize = this.pageSizeOptions[0];
      }
      if (this.tableDefinition.options.defaultPageSize) {
        this.defaultPageSize = this.tableDefinition.options.defaultPageSize;
        const pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: this.defaultPageSize };
        this.vaFilteredTableService.updatePaging(pageEvent);
        this.previousPageSize = this.defaultPageSize;
      }
      if (this.tableDefinition.options.disableMultiColumnSorting) {
        this.displaySortButton = false;
      }
    }

    this.actionableRows$ = combineLatest([
      this.vaFilteredTableService.selectedRows$,
      this.vaFilteredTableService.displayedRows$,
    ]).pipe(
      map(([selected, displayed]) => {
        if (selected.length === 0) {
          return displayed;
        }
        return selected;
      }),
    );

    this.subscriptions.add(this.vaFilteredTableService.paging$, (p) => {
      if (p && p.pageIndex !== this.paginator.pageIndex) {
        this.paginator.pageIndex = p.pageIndex;
      }
    });

    this.subscriptions.add(this.filters.valueChanges, (x) => {
      this.presetService.setSelectedPreset(x);
    });

    this.vaFilteredTableService.init();
  }

  ngAfterViewInit(): void {
    const scrollWrapper = document.getElementById('scroll-wrapper');
    if (scrollWrapper) {
      scrollWrapper.addEventListener('scroll', this.showShadowOnScroll, { capture: false, passive: true });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.destroy();
    this.dataSource?.destroy();
    const scrollWrapper = document.getElementById('scroll-wrapper');
    if (scrollWrapper) {
      scrollWrapper.removeEventListener('scroll', this.showShadowOnScroll, { capture: false });
    }
  }

  columnsChanged($event: string[]): void {
    this.displayedColumns = [...this.pinnedLeftCols, ...$event, ...this.pinnedRightCols];
    this.columnsChangedEventEmitter.emit({ tableId: this.tableDefinition.id, columns: $event });
  }

  private export(): void {
    this.csvExportButtonClicked.emit({
      filters: this.filters.value,
      sortingOptions: this.sortService.value(),
      displayedColumns: this.displayedColumns,
    });
    if (this.useCSVExportAsButton) {
      return;
    }
    const dialog = this.dialog.open(CsvExporterDialogComponent, {
      maxWidth: '550px',
      data: {
        tableDataService: this.tableDataService,
        exportAllDataService: this.exportAllDataService,
        tableDefinition: this.tableDefinition,
        customCellComponents: this.table.getCustomCellComponents().toArray(),
        filters: this.filters,
        sortOptions: this.sortService.value(),
        displayedColumns: this.displayedColumns,
        selectedRows$: this.vaFilteredTableService.selectedRows$,
        displayedRows$: this.vaFilteredTableService.displayedRows$,
        exportDialogDefinition: this.tableDefinition.options?.exportDialogDefinition,
      },
    } as MatDialogConfig);

    dialog.afterClosed().subscribe((exportInfo: CustomExportEvent) => {
      if (exportInfo) {
        this.customExportEvent.emit(exportInfo);
      }
    });
  }

  bulkActionMenuItemClicked(actionID: string): void {
    if (actionID === this.exportCSVBulkActionID) {
      return this.export();
    }

    const event: BulkActionClickedEvent = {
      bulkActionID: actionID,
      currentSettings: {
        filters: this.filters.value,
        sortingOptions: this.sortService.value(),
        displayedColumns: this.displayedColumns,
      },
    };
    this.bulkActionClicked.emit(event);
  }

  onPage($event: PageEvent): void {
    this.vaFilteredTableService.updatePaging($event);
    if ($event.pageSize !== this.previousPageSize) {
      this.vaFilteredTableService.resetPaging();
    }
    this.previousPageSize = $event.pageSize;
  }

  updateSelectedRows($event: T[]): void {
    this.vaFilteredTableService.updateSelectedRows($event);
  }

  updateDisplayedRows($event: T[]): void {
    this.vaFilteredTableService.updateDisplayedRows($event);
  }

  cellEventEmitted($event: any): void {
    this.cellEventEmitter.emit($event);
  }

  rowClicked($event: T): void {
    this.rowClick.emit($event);
  }

  dataClickedWithEvent($event: T): void {
    this.dataClickWithEvent.emit($event);
  }

  private showShadowOnScroll(): void {
    const scrollLeft = document.getElementById('scroll-wrapper').scrollLeft;
    const scrollWrapper = document.querySelector('#scroll-wrapper table.mat-mdc-table');

    // If user scrolls past 2px in table, show the shadow
    if (scrollLeft >= 2) {
      scrollWrapper.classList.add('show-sticky-shadow');
    } else {
      scrollWrapper.classList.remove('show-sticky-shadow');
    }
  }

  handleFilterFromPreset(filterData: any): void {
    const controls = this.filters.controls;
    Object.keys(this.filters.controls).forEach((filter) => {
      if (!!filterData && filterData[filter] !== undefined) {
        controls[filter].setValue(filterData[filter]);
      } else {
        if (Array.isArray(this.filters.controls[filter].value)) {
          controls[filter].setValue([]);
        } else {
          controls[filter].setValue(undefined);
        }
      }
    });
    this.filters.controls = controls;
  }

  handleSortFromPreset(sorts: Sort[]): void {
    this.sortService.removeAll();
    sorts.forEach((sort: Sort) => {
      this.sortService.addColumnWithSortDirection(sort.column, sort.orientation);
    });
    this.sortService.applySortSettings();
  }

  public getSelectedRows(): T[] {
    return this.table.getSelectedRows();
  }

  handleColumnButtonClicked(): void {
    this.columnButtonClicked.emit();
  }

  resetPaging(): void {
    this.vaFilteredTableService.resetPaging();
  }
}
