import { EventEmitter, InjectionToken, Type } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterGroup } from '@vendasta/va-filter2';

/*
NOTE:
The advanced column organizer component shares part of these interfaces to handle input values from
the filter2 table. If you're adding fields or changing existing fields, check with the advanced organizer
to ensure functionality is maintained. Matching interfaces can be found here:
/frontend/angular/projects/uikit/src/lib/va-mat-table/actions/advanced-column-organizer/advanced-column-organizer.ts
*/

export interface CustomCellComponent<T> {
  columnDefinition: ColumnDefinition;
  element: T;
  translateService?: TranslateService;
  eventEmitter?: EventEmitter<any>;
  exportData?: () => any;
}

export enum SortDirection {
  SORT_DIRECTION_UNSET = 0,
  SORT_DIRECTION_ASCENDING = 1,
  SORT_DIRECTION_DESCENDING = 2,
}

export enum Pinned {
  PINNED_UNSET = 0,
  PINNED_LEFT = 1,
  PINNED_RIGHT = 2,
}

export interface SortOptions {
  sortable: boolean;
  initialSortDirection?: SortDirection;
}

// Used to define how columns should be shown to sort
// ex: `A to Z` or `1 to 9`
export enum ColumnType {
  COLUMN_TYPE_UNSET = 0,
  COLUMN_TYPE_STRING = 1,
  COLUMN_TYPE_NUMBER = 2,
  COLUMN_TYPE_DATE = 3,
}

export enum ColumnWidth {
  WIDTH_WIDE = 'width-wide',
  WIDTH_DEFAULT = 'width-default',
  WIDTH_ACTION = 'width-action',
  WIDTH_NARROW = 'width-narrow',
}

export interface ColumnDefinition {
  id: string;
  // Used as the text for Headers, and Column Name for Multi-Sort and Column Placement. Always use Translation string if available.
  displayName: string;
  //
  width?: ColumnWidth;
  // The field in which the data for a column is stored, use dot notation for nested field. Use when plain value is displayed.
  // If omitted, will use the id as the field.
  field?: string;
  // When not using a custom cell component this will apply the translate pipe to cell value.
  translateCell?: boolean;
  // Custom Cell Component to render the cell.
  cellComponent?: Type<any>;
  // Custom Header Component to render in header of column
  headerComponent?: Type<any>;
  // If you provide sort options the column will be sortable
  sortOptions?: SortOptions;
  // applies the sticky (left) or stickyEnd (right) decorator to the column, ensure it will be visible regardless of horizontal scroll.
  pinned?: Pinned;
  type?: ColumnType;
  // toStringFunction is the third way to get the value from a cell, used for converting complex values into a string for display or export.
  // element will always be of type T, but it adding it here was a bit messy.
  toStringFunction?: (element: any) => string;
  // First time the table is loaded, this column will be hidden and will stay that way until users toggle the visibilty using the column organizer
  hidden?: boolean;
}

export interface CsvExporterDialogInterface {
  headerText: string;
  descriptionText?: string;
  exportOptions?: CsvExporterDialogExportOptionInterface[];
}

// used internally to populate the row-selection options, like "selected rows (42)"
export interface CsvExporterDialogExportOptionInterface {
  id: string;
  selectionText: string;
  extraSelectionText?: string;
  selectionHelpText?: string;
}

// click a button to choose action - then pops modal which is outside of library
export interface BulkActionsOptionsInterface {
  actionButtonText: string; // e.g. 'Download'
  actionButtonMaterialIcon: string; // e.g. 'cloud_download'
  actionId: string;
  description?: string;
}

export interface OnCSVTransform {
  // Used to transform data from a cell component when there's a need for accessing internal component data
  exportData: () => string;
}

export interface SortingLabels {
  ascending: string;
  descending: string;
}

export interface ColumnSortingLabels {
  number: SortingLabels;
  string: SortingLabels;
  date: SortingLabels;
  default: SortingLabels;
}

export interface ExportLabels {
  buttonText: string;
  // The tooltip is formed by concatenating buttonTooltipBeforeCount + count of rows to be exported + buttonTooltipAfterCount
  buttonTooltipBeforeCount: string;
  buttonTooltipAfterCount: string;
  description?: string;
}

export interface ColumnSelectorLabels {
  buttonText: string;
}

export interface GenericTableLabels {
  noResults: string;
  sortBy: string;
  thenSortBy: string;
  addAnotherSortColumn: string;
  chooseColumnToSortBy: string;
  none: string;
  cancel: string;
  update: string;
  columnSorting: ColumnSortingLabels;
  export: ExportLabels;
  columnSelector?: ColumnSelectorLabels;
  actionsButton?: string;
}

export interface TableOptions {
  // Selectable rows allows for a pinned checkbox on the left most column of the table. The parent of this component can then take an action
  // on the selected rows.
  selectableRows?: boolean;
  csvExportEnabled?: boolean;
  exportDialogDefinition?: CsvExporterDialogInterface;
  // Bulk actions that aren't exporting to a CSV
  bulkActions?: BulkActionsOptionsInterface[];
  // Page size options allow for a custom list of page sizes to be used by the table. The first entry in the list will be used as the
  // default page size.
  pageSizeOptions?: number[];
  defaultPageSize?: number;
  disableMultiColumnSorting?: boolean;
  tableTitle?: string;
}

export enum ColumnOrganizerType {
  BASIC_ORGANIZER = 0,
  ADVANCED_ORGANIZER = 1,
}

export interface GroupDefinition {
  id: string;
  name: string;
  columns: string[];
}

export interface DetailRow {
  column: ColumnDefinition;
  expandable?: boolean;
  showDetailRow: (row: any) => boolean;
}

export interface TableDefinition {
  id: string;
  filters?: FilterGroup;
  columns: ColumnDefinition[];
  detailRow?: DetailRow;
  labels: GenericTableLabels;
  options?: TableOptions;
  groups?: GroupDefinition[];
  columnOrganizerType?: ColumnOrganizerType;
  rowEqualityFunc?: (row1: any, row2: any) => boolean;
}

/** Injection token that is used to configure sorting, filtered tables, etc... */
export const TABLE_DEFINITION = new InjectionToken<TableDefinition>('TableDefinition');
