import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LexiconModule } from '@galaxy/lexicon';
import { TranslateModule } from '@ngx-translate/core';
import { UIKitModule } from '@vendasta/uikit';
import { FilterModule } from '@vendasta/va-filter2';
import baseTranslation from './assets/i18n/en_devel.json';
import { CsvExporterDialogComponent } from './csv-exporter/csv-exporter-dialog.component';
import { CsvExporterService } from './csv-exporter/csv-exporter.service';
import {
  VaFilteredMatTableActionsDirective,
  VaFilteredMatTableComponent,
  VaFilteredMatTableTooltipDirective,
} from './va-filtered-mat-table/va-filtered-mat-table.component';
import { CellValueService } from './va-model-driven-mat-table/cell-value.service';
import { DateColumnComponent } from './va-model-driven-mat-table/column-components/date-column.component';
import { TableCellRefDirective } from './va-model-driven-mat-table/table-cell-ref.directive';
import { TableCellComponent } from './va-model-driven-mat-table/table-cell.component';
import { TableHeaderComponent } from './va-model-driven-mat-table/table-header.component';
import { VaModelDrivenMatTableComponent } from './va-model-driven-mat-table/va-model-driven-mat-table.component';
import { VaPresetViewComponent } from './va-preset-view/va-preset-view.component';
import { VaPresetViewService } from './va-preset-view/va-preset-view.service';
import { VaTableSortHeaderComponent } from './va-table-sort/va-table-sort-header/va-table-sort-header.component';
import { VaTableSortComponent } from './va-table-sort/va-table-sort.component';
import { VaTableSortService } from './va-table-sort/va-table-sort.service';

@NgModule({
  declarations: [
    VaModelDrivenMatTableComponent,
    TableCellRefDirective,
    TableCellComponent,
    TableHeaderComponent,
    DateColumnComponent,
    VaTableSortComponent,
    VaPresetViewComponent,
    VaTableSortHeaderComponent,
    VaFilteredMatTableComponent,
    VaFilteredMatTableActionsDirective,
    VaFilteredMatTableTooltipDirective,
    CsvExporterDialogComponent,
  ],
  exports: [
    DateColumnComponent,
    VaFilteredMatTableComponent,
    VaModelDrivenMatTableComponent,
    VaFilteredMatTableActionsDirective,
    VaFilteredMatTableTooltipDirective,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatButtonToggleModule,
    OverlayModule,
    DragDropModule,
    TranslateModule,
    LexiconModule.forChild({
      componentName: 'common/va-filter2-table',
      baseTranslation: baseTranslation,
    }),
    MatSelectModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    CdkTableModule,
    MatTableModule,
    FilterModule,
    UIKitModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDialogModule,
    MatRadioModule,
    MatDividerModule,
    FormsModule,
  ],
  providers: [CellValueService, CsvExporterService, VaPresetViewService, VaTableSortService],
})
export class Filter2TableModule {}
