import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ColumnWidth, Pinned, TableDefinition } from '../va-table-definition/va-table-definition';
import { CellValueService } from './cell-value.service';
import { TranslateService } from '@ngx-translate/core';
import { CollectionViewer, isDataSource, ListRange } from '@angular/cdk/collections';
import { SubscriptionList } from '@vendasta/rx-utils';
import { debounceTime } from 'rxjs/operators';
import { TableCellComponent } from './table-cell.component';
import { VaFilteredMatTableService } from '../va-filtered-mat-table/va-filtered-mat-table.service';

declare type CdkTableDataSourceInput<T> = DataSource<T> | Observable<ReadonlyArray<T> | T[]> | ReadonlyArray<T> | T[];

const SELECT_COLUMN_ID = 'select';
const EXPAND_COLUMN_ID = 'expand';

@Component({
  selector: 'va-model-driven-mat-table',
  templateUrl: './va-model-driven-mat-table.component.html',
  styleUrls: ['./va-model-driven-mat-table.component.scss'],
})
export class VaModelDrivenMatTableComponent<T> implements CollectionViewer, OnDestroy, OnInit {
  Pinned = Pinned;
  ColumnWidth = ColumnWidth;

  @Input() dataSource: CdkTableDataSourceInput<T>;

  private _tableDefinition: TableDefinition;
  @Input()
  set tableDefinition(def: TableDefinition) {
    this._tableDefinition = def;
    if (!this._tableDefinition.options) {
      this._tableDefinition.options = {};
    }

    if (this._tableDefinition.columns) {
      for (const column of this._tableDefinition.columns) {
        if (!column.width) {
          column.width = ColumnWidth.WIDTH_DEFAULT;
        }
      }
    }

    if (this._tableDefinition.options.selectableRows) {
      this._displayedColumns = [SELECT_COLUMN_ID].concat(this._displayedColumns);
    }
  }
  get tableDefinition(): TableDefinition {
    return this._tableDefinition;
  }

  private _displayedColumns: string[] = [];

  @Input()
  set displayedColumns(columns: string[]) {
    const updatedColumns = [];
    if (this.tableDefinition && this.tableDefinition.options.selectableRows) {
      updatedColumns.push(SELECT_COLUMN_ID);
    }
    this._displayedColumns = updatedColumns.concat(columns);

    if (this.tableDefinition && this.tableDefinition.detailRow?.expandable) {
      this._displayedColumns = this._displayedColumns.concat(EXPAND_COLUMN_ID);
    }
  }
  get displayedColumns(): string[] {
    return this._displayedColumns;
  }

  @Input() preselectedRows: T[] = [];

  @Output() rowSelection: EventEmitter<T[]> = new EventEmitter();
  @Output() allRows: EventEmitter<T[]> = new EventEmitter();

  @Output() cellEvent: EventEmitter<any> = new EventEmitter();
  @Output() rowClick: EventEmitter<T> = new EventEmitter();
  @Output() dataClickWithEvent: EventEmitter<any> = new EventEmitter();

  @ViewChildren('cellComponent')
  private readonly tableCellComponents: QueryList<TableCellComponent<T>>;

  private readonly subscriptions = SubscriptionList.new();
  selectedRows$$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  private data: T[] = [];
  viewChange: Observable<ListRange>;

  currentlyExpandedRow: T;

  constructor(
    private readonly cellValueService: CellValueService<T>,
    public readonly translateService: TranslateService,
    private readonly vaFilteredMatTableService: VaFilteredMatTableService<T>,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.selectedRows$$.asObservable().pipe(debounceTime(50)), (selectedRows) =>
      this.rowSelection.emit(selectedRows),
    );

    this.subscriptions.add(this.vaFilteredMatTableService.selectedRows$.pipe(debounceTime(100)), (result) => {
      if (this.tableDefinition.rowEqualityFunc && result !== this.selectedRows$$.getValue().reverse()) {
        this.clearSelection();
        result.forEach((r: T) => this.toggleSelection(r));
      }
    });

    this.preselectedRows.map((r) => this.toggleSelection(r));
    this._observeRenderChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.destroy();
  }

  getCellValue(id: string, field: string, element: T): any {
    return this.cellValueService.getCellValue(id, field, element);
  }

  /** Toggle the selection of a row */
  toggleSelection(row: T): void {
    let index = -1;
    if (!this.tableDefinition.rowEqualityFunc) {
      index = this.selectedRows$$.getValue().indexOf(row);
    } else {
      index = this.selectedRows$$
        .getValue()
        .findIndex((selectedRow) => this.tableDefinition.rowEqualityFunc(row, selectedRow));
    }

    if (index >= 0) {
      this.selectedRows$$.next([
        ...this.selectedRows$$.getValue().slice(0, index),
        ...this.selectedRows$$.getValue().slice(index + 1),
      ]);
    } else {
      this.selectedRows$$.next([...this.selectedRows$$.getValue(), row]);
    }
  }

  clearSelection(): void {
    this.selectedRows$$.next([]);
  }

  /** Whether a given row is selected */
  isSelected(row: T): boolean {
    if (!this.tableDefinition.rowEqualityFunc) {
      return this.selectedRows$$.getValue().includes(row);
    } else {
      return this.selectedRows$$
        .getValue()
        .some((selectedRow) => this.tableDefinition.rowEqualityFunc(row, selectedRow));
    }
  }

  /** Whether the number of selected elements on the current page matches the total number of rows on the current page. */
  isAllSelected(): boolean {
    if (!this.tableDefinition.rowEqualityFunc) {
      const numSelected = this.selectedRows$$.getValue().length;
      const numRows = this.data.length;
      return numSelected === numRows;
    } else {
      return this.data.reduce((areSelected, row) => areSelected && this.isSelected(row), true);
    }
  }

  /** Selects all rows on the current page if they are not all selected; otherwise clear selection. */
  toggleAllRows(): void {
    if (!this.tableDefinition.rowEqualityFunc) {
      this.selectedRows$$.next(this.isAllSelected() ? [] : [...this.data]);
    } else {
      if (this.isAllSelected()) {
        this.selectedRows$$.next([]);
      } else {
        const filtered = this.data.filter((row) => !this.isSelected(row));
        this.selectedRows$$.next([...this.selectedRows$$.getValue(), ...filtered]);
      }
    }
  }

  /** Set up a subscription for the data provided by the data source.
   * Duplicated the behavior of CDK Table so that we can have access to data for displayed rows. */
  private _observeRenderChanges(): void {
    // If no data source has been set, there is nothing to observe for changes.
    if (!this.dataSource) {
      return;
    }

    let dataStream: Observable<T[] | ReadonlyArray<T>> | undefined;

    if (isDataSource(this.dataSource)) {
      dataStream = this.dataSource.connect(this);
    } else if (this.dataSource instanceof Observable) {
      dataStream = this.dataSource;
    } else if (Array.isArray(this.dataSource)) {
      dataStream = of(this.dataSource);
    }

    this.subscriptions.add(dataStream, (data) => {
      this.data = data || [];
      this.allRows.emit(this.data);
      if (!this.tableDefinition.rowEqualityFunc) {
        this.selectedRows$$.next(this.selectedRows$$.getValue().filter((row) => this.data.includes(row)));
      }
    });
  }

  public getSelectedRows(): T[] {
    return this.selectedRows$$.getValue();
  }

  public getCustomCellComponents(): QueryList<TableCellComponent<T>> {
    return this.tableCellComponents;
  }

  handleExpandChange(row: T): void {
    this.currentlyExpandedRow !== row ? (this.currentlyExpandedRow = row) : (this.currentlyExpandedRow = null);
  }

  rowClicked(row: T): void {
    this.rowClick.emit(row);
  }

  dataClickedWithEvent(element: T, event: MouseEvent): void {
    this.dataClickWithEvent.emit({
      element: element,
      event: event,
    });
  }
}
